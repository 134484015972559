<template>
  <v-img :src="require('@/assets/PIM_Contact.png')" cover max-height="878">
    <v-container class="mx-auto customContainer">
      <v-row class="responsiveFont" justify="center">
        <v-col
          cols="12"
          md="4"
          v-for="(document, index) in documents_new"
          :key="index"
          align="center"
        >
          <v-card class="pa-4 text-center" variant="default">
            <h5>{{ document.name }}</h5>
            <v-btn
              :color="document.color"
              :href="document.link"
              download
              class="mt-2"
            >
              Download
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      documents_new: [
        {
          name: "Nota Conceptuala",
          link: "/Documents/New/Nota_conceptuala-PIM_parcul_industrial_tehnologic_MOSNITA_NOUA-jud_TIMIS.pdf",
          color: "blue",
        },
        {
          name: "Tema de Proiectare",
          link: "/Documents/New/Tema_de_proiectare-PIM_parcul_industrial_tehnologic_MOSNITA_NOUA-jud_TIMIS.pdf",
          color: "blue",
        },
        {
          name: "Studiu de Fezabilitate",
          link: "/Documents/New/Studiu_de_fezabilitate-PIM_parcul_industrial_tehnologic_MOSNITA_NOUA-jud_TIMIS.pdf",
          color: "blue",
        },
      ],
    };
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}
.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: white;
  padding: 0 20px;
  transition: color 0.3s ease-in-out;
  backdrop-filter: blur(5px);
}

.image-overlay:hover {
  color: rgb(65, 217, 255);
}

.v-container:first-child {
  background-color: #fff;
}
.customContainer {
  max-width: 1000px;
  min-height: 2000px;
  max-height: fit-content;
  margin: 0 auto;
}
.center {
  text-align: center;
}
.textSubtitle {
  color: #999;
}
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 15px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 13px;
  }
}
</style>

<!--template>
  <v-row>
    <v-carousel hide-delimiter-background show-arrows="hover" cycle="true">
      <v-carousel-item :src="require('@/assets/PIM_13.png')" cover>
      </v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/PIM_11.png')"
        cover
      ></v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_12.png')"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-row>
  <v-container class="responsiveFont responsiveWidth">
    <v-row
      ><v-col cols="12"
        ><h2 class="textTitle">
          INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS County
          <h6 class="textSubtitle">
            integrated industrial park for the development of applications,
            products and services through advanced technologies
          </h6>
        </h2>
      </v-col></v-row
    >
    <v-divider length="90%"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-img :src="require('@/assets/PIM_10.png')" contain class="textBox" />
        <v-divider length="90%"></v-divider>

        <p class="textBox">
          The fact that Romania, in general, and Timisoara in particular, has a
          positive gap in the adoption of advanced technologies represents a
          great opportunity for the MOSNITA NOUA industrial park, allowing it to
          develop focused on what is more advanced from the beginning. That is
          precisely why the heart of the MOSNITA NOUA industrial park will have
          a mammoth server for big data processing and management but also with
          internet power with the fastest access and speed starting with 5G.
        </p>
        <p class="textBox">
          This way of organizing will allow companies and freelancers a
          perspective to use to create applications with a significant impact on
          businesses that take place locally and globally, with the immediate
          effect of the global megatrend of continuous innovation.
        </p>
        <v-img :src="require('@/assets/PIM_6.png')" contain class="textBox" />
        <v-divider length="90%"></v-divider>
        <p class="textBox">
          Some new professions that will become professions of the future, such
          as big data manager, application new technology management,
          specialists in nanomedicine, wellness consulting, scientific ethics,
          time broker, virtual lawyer, personal brand management, aquaponic
          management, as well as rewilding, telesurgery, recycling designer,
          simplification experts, roboconsulting, interconnected communicator,
          and many others, will develop as a result of the collaboration between
          the Timisoara university environment and the business environment
          created in the MOSNITA NOUA INDUSTRIAL PARK.
        </p>
        <p class="textBox">
          The MOSNITA NOUA INDUSTRIAL PARK clearly targets those industries and
          technologies that determine the "demand" for a more ecological world,
          causing changes in the labor market and requiring new skills from
          professionals in the category presented above. Precursors of future
          activities include green engineers who assess how much power a device
          uses and how to use it more efficiently, and urban farmers who help
          grow vertical gardens, fruits, and vegetables on the roofs and walls
          of buildings. In the future, they can reduce air pollution at the
          local level, better insulate buildings from the unfavorable factors of
          the urban environment, and increase biodiversity. Professions such as
          traffic coordinators, tracking managers, urban scrap and waste mining,
          3D printing designer in construction, urban ecologist, remote workers
          in the field of health protection, "smart" environmental designer, and
          many others will make the industrial park-technological MOSNITA NOUA
          the European center of attraction and interest in the development of
          technologically advanced innovation. Synergistically, these
          professions will be vocationally correlated with the workforce that is
          formed in the university environment of Timisoara.
        </p>
        <p class="textBox">
          Specialist in climate control, nanomedicine-treatment that involves
          robots of microscopic dimensions that enter the body to prevent or
          fight against serious diseases such as cancer, vertical farmer for
          specially placed construction, right in the middle of crowded urban
          areas, as much as the sources of food closer to the people who consume
          it, thus reducing the impact on the environment as well as the space
          occupied by crops — all these professional models of the future will
          basically represent the object of activity of the occupants of the
          "heart" building of the MOSNITA NOUA INDUSTRIAL park. This circular
          building will host only companies, business incubators, small or
          larger companies, focused exclusively on the development of applied
          technologies for a longer and more beautiful life.
        </p>
        <v-img :src="require('@/assets/PIM_9.png')" contain class="textBox" />
        <v-divider length="90%"></v-divider>

        <p class="textBox">
          But for all these goals to be achieved in the MOSNITA NOUA INDUSTRIAL
          PARK, it is necessary to establish the legal framework through a
          Decision by the Local Council approving the facilities granted by the
          state and territorial and administrative division by law 186l/2013
          provided in CHAPTER V, art. 20, paragraph 1, as follows:
        </p>

        <p class="textBox">
          CHAPTER V Facilities ART. 20 Facilities (1) The industrial park title
          grants the park administrator and residents the right to the following
          facilities:
        </p>
        <ul class="textList">
          <li>
            a) exemption from paying the fees charged for changing the
            destination or for removing from the agricultural circuit the land
            belonging to the industrial park;
          </li>
          <li>
            b) exemption from paying the land tax, corresponding to the land
            belonging to the industrial park, according to the provisions of
            art. 257 letter l) from Law no. 571/2003 regarding the Fiscal Code,
            with subsequent amendments and additions;
          </li>
          <li>
            c) exemption from paying the tax on buildings, corresponding to the
            buildings that are part of the infrastructure of the industrial
            park, in accordance with art. 250 para. (1) point 9 of Law no.
            571/2003, with subsequent amendments and additions;
          </li>
          <li>
            d) exemptions, only with the consent of the local public
            authorities, from the payment of any taxes due to the local budgets
            of the administrative-territorial units for the issuance of any town
            planning certificates, building permits and/or building demolition
            permits for the land and infrastructure buildings of the park, which
            are an integral part of the industrial park;
          </li>
          <li>
            e) other facilities that can be granted, according to the law, by
            the local public administration authorities.
          </li>
        </ul>

        <p class="textBox">
          The facilities granted by the SC GEVAGRO SRL administrator of the
          MOSNITA NOUA INDUSTRIAL PARK will consist of:
        </p>

        <ul class="textList">
          <li>
            Complete administration services in PIM, including the maintenance
            of common spaces, guarding, and security services (including cyber
            security through HUB ITC), as well as ecological management of
            industrial waste.
          </li>
          <li>
            Services related to human resources activities, the development of
            the dual school by prioritizing park members for dual professional
            training, and access to educational fairs of synergistic
            professions.
          </li>
          <li>
            The construction of halls on demand, services for obtaining all
            approvals.
          </li>
          <li>
            Ensuring the centralized supply to the clients of the MOSNITA NOUA
            INDUSTRIAL PARK of electricity, methane gas, water, sewage, fiber
            optic, certified hydrant system, intelligent public lighting,
            centralized parking, electric transport for employees inside the
            park and/or from home.
          </li>
          <li>
            Ensuring conditions required by firefighters for guarding and
            protection against fires in a centralized way, eliminating the need
            for each investment to have its own water tank and access to
            logistics.
          </li>
          <li>
            Through the central area of the park, which focuses on advanced
            technologies and cyber intelligence, services will be provided at
            priority rates in the fields of human resources, online marketing,
            online commerce, database services, ERP, CRM, BMP.
          </li>
          <li>
            Ensuring technology-related services such as 3D design, industrial
            design, electric mobility, HYPERLOOP transfer of county staff,
            augmented and virtual reality technologies, robotics, and artificial
            intelligence.
          </li>
          <li>
            Ensuring necessary logistic facilities at a lower price than outside
            the park, including electricity, sewage, TV cable, broadband, and
            high-speed internet.
          </li>
          <li>
            Priority access to the service center of MOSNITA NOUA INDUSTRIAL
            PARK, which includes a kindergarten, after-school program, mall
            street, banking service centers, restaurants with public food and
            collective catering services, hotel, medical services, etc.
          </li>
          <li>
            Partnership benefits within the ITC coworking HUB (smart
            applications, conference meeting spaces, product presentations,
            etc.).
          </li>
          <li>
            Administration of compensatory advantages granted by companies in
            the MOSNITA NOUA INDUSTRIAL PARK to the town hall for exemptions
            from various local taxes, such as roundabouts with boards promoting
            company x, playgrounds or sports facilities with equipment from
            company x.
          </li>
          <li>Investor protection facilities</li>
          <li>
            Consulting in de minimis facilities for investments up to 10 million
            euros and regional state aid for investments greater than 10 million
            euros made in the MOSNITA NOUA INDUSTRIAL PARK, both within the
            limit of the ceiling established by ministerial order no. 2980/2013.
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      screenSize: 0,
    };
  },
  methods: {
    getAspectRatio() {
      this.screenSize = window.screen.width / window.screen.height;
    },
  },
};
</script>

<style scoped>
.textComponent {
  margin: 20px 20px;
}
.textBox {
  text-align: justify;
  margin: 25px 25px;
}
.textTitle {
  text-align: center;
  margin: 25px 25px;
}
.textSubtitle {
  color: #999;
}
.v-divider {
  margin: 0px 5%;
}
.moreButton {
  position: absolute;
  margin-left: 10%;
  margin-top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.aspect-ratio {
  position: relative;
}

.aspect-ratio > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.textList {
  text-align: justify;
  margin: 40px;
}
/* Default font size for all screens */
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 13px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 11px;
  }
}
/* For desktop screens */
@media only screen and (min-width: 1200px) {
  .responsiveWidth {
    max-width: 80%;
  }
}

/* For tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .responsiveWidth {
    max-width: 90%;
  }
}

/* For smartphone screens */
@media only screen and (max-width: 767px) {
  .responsiveWidth {
    max-width: 100%;
  }
}
.noBulletPoints {
  list-style-type: none;
  font-style: italic;
}
.centerList {
  display: flex;
}
</style-->
