<template>
  <v-list>
    <v-list-item
      lines="two"
      title="Mosnita Noua Industrial Park"
      subtitle="PIM"
    ></v-list-item
  ></v-list>
  <v-divider></v-divider>
  <v-list v-model:opened="open">
    <!--router-link to="/" style="text-decoration: none; color: inherit">
      <v-list-item
        value="Home"
        title="Acasa"
        prepend-icon="mdi-home"
      ></v-list-item>
    </router-link-->
    <router-link to="/" style="text-decoration: none; color: inherit">
      <v-list-item
        value="Home"
        title="Home"
        prepend-icon="mdi-home"
      ></v-list-item>
    </router-link>

    <!--router-link to="/location" style="text-decoration: none; color: inherit">
      <v-list-item
        value="HomeLocation"
        title="Location"
        prepend-icon="mdi-map-marker"
      ></v-list-item>
    </router-link-->

    <!--router-link to="/objectives" style="text-decoration: none; color: inherit">
      <v-list-item
        value="HomeObjectives"
        title="Organization"
        prepend-icon="mdi-flag"
      ></v-list-item>
    </router-link-->

    <router-link to="/parcels" style="text-decoration: none; color: inherit">
      <v-list-item
        value="HomeParcels"
        title="Parcels"
        prepend-icon="mdi-land-plots"
      ></v-list-item>
    </router-link>

    <router-link to="/facilities" style="text-decoration: none; color: inherit">
      <v-list-item
        value="HomeFacilities"
        title="Organization & Regulations (EN & RO)"
        prepend-icon="mdi-city"
      ></v-list-item>
    </router-link>

    <router-link to="/objectives" style="text-decoration: none; color: inherit">
      <v-list-item
        value="HomeObjectives"
        title="Studiu de Fezabilitate - NC - PT"
        prepend-icon="mdi-file-document-outline"
      ></v-list-item>
    </router-link>

    <!--v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-information"
          title="Despre noi"
        ></v-list-item>
      </template>
      <router-link to="/vision" style="text-decoration: none; color: inherit">
        <v-list-item value="vision" title="Viziunea noastra"></v-list-item>
      </router-link>
      <router-link to="/mission" style="text-decoration: none; color: inherit">
        <v-list-item value="mission" title="Misiunea noastra"></v-list-item>
      </router-link>
      <router-link to="/about" style="text-decoration: none; color: inherit">
        <v-list-item value="about" title="Afacerea noastra"></v-list-item>
      </router-link>
    </v-list-group>

    <v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-city"
          title="Structura PIM"
        ></v-list-item>
      </template>
      <router-link to="/mosnita" style="text-decoration: none; color: inherit">
        <v-list-item value="Mosnita" title="Big Data Center"></v-list-item>
      </router-link>
      <router-link to="/albina" style="text-decoration: none; color: inherit">
        <v-list-item value="Albina" title="HUB IT & C"></v-list-item>
      </router-link>
      <router-link to="/urseni" style="text-decoration: none; color: inherit">
        <v-list-item value="Urseni" title="Servicii"></v-list-item>
      </router-link>
      <router-link
        to="/aristocrat"
        style="text-decoration: none; color: inherit"
      >
        <v-list-item
          value="Aristocrat"
          title="Plan - Hale - Parcele"
        ></v-list-item>
      </router-link>
    </v-list-group>

    <v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-book"
          title="Regulament PIM"
        ></v-list-item>
      </template>
      <router-link
        to="/passive-house"
        style="text-decoration: none; color: inherit"
      >
        <v-list-item value="House" title="Big Data HUB IT"></v-list-item>
      </router-link>
      <router-link
        to="/photovoltaic"
        style="text-decoration: none; color: inherit"
      >
        <v-list-item value="photovoltaic" title="Servicii"></v-list-item>
      </router-link>
      <router-link to="/nzeb" style="text-decoration: none; color: inherit">
        <v-list-item value="NZEB" title="Hale"></v-list-item>
      </router-link>
    </v-list-group>
    <router-link to="/program" style="text-decoration: none; color: inherit">
      <v-list-item
        value="program"
        title="Program"
        prepend-icon="mdi-clock-time-eight"
      ></v-list-item>
    </router-link-->
    <router-link to="/contact" style="text-decoration: none; color: inherit">
      <v-list-item
        value="location"
        title="Contact"
        prepend-icon="mdi-phone"
      ></v-list-item>
    </router-link>
  </v-list>
</template>
<script>
export default {
  props: {
    drawerProp: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    open: ["Users"],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
  }),
};
</script>
